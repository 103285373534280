// @flow
import { decorate, observable, action } from 'mobx';

import Api from '../../api';

class SimulationStore {
  constructor() {
    this.simulations = [];
    this.simulationsList = [];
    this.totalCount = 0;
    this.selectedSimulation = null;
    this.creatingSimulation = false;
    this.updatingSimulation = false;
    this.fetchSimulationState = null;
  }

  getSimulations = async (queryParams) => {
    const { data } = await Api.getSimulations(queryParams);
    if (data) {
      this.simulations = data.simulations;
      this.totalCount = data.totalCount;
    }

    return this.simulations;
  }

  getSimulationsList = async () => {
    try {
      if (this.simulationsList?.length) return this.simulationsList;

      const { data } = await Api.getSimulationsList();
      if (data?.simulationsList?.length) {
        this.simulationsList = data.simulationsList;
      }

      return this.simulationsList;
    } catch (err) {
      return [];
    }
  }

  getSimulation = async (simulationId) => {
    try {
      const { data } = await Api.getSimulation(simulationId);

      this.selectedSimulation = data;

      return data;
    } catch (e) {
      this.fetchSimulationState = e.response.status;
    }
  }

  checkSimulationPromptGenerationStatus = (simulationData) => {
    const { extra: { contentAreas } } = simulationData;
    const genAIContentAreas = contentAreas.filter(({ isGenAI }) => isGenAI) || [];

    if (!genAIContentAreas.length) {
      return 'NO-AI';
    }

    if (genAIContentAreas.some((ca) => ca.extra.promptGenerationStatus === 'PENDING')) {
      return 'PENDING';
    }

    if (genAIContentAreas.every((ca) => ca.extra.promptGenerationStatus === 'SUCCESS')) {
      return 'SUCCESS';
    }

    return false;
  }

  waitForContentAreaSave = async (response) => {
    let simulationData = response.data.data;
    let allContentAreaPromptsUpdated = false;

    const saveWaitTimeout = 5 * 60 * 1000; // 5 mins
    const loopStartTime = Date.now();

    while (Date.now() < loopStartTime + saveWaitTimeout) {
      const status = this.checkSimulationPromptGenerationStatus(simulationData);

      if (status === 'SUCCESS' || status === 'NO-AI') {
        allContentAreaPromptsUpdated = true;
        break;
      } else {
        // poll every 5s
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => setTimeout(resolve, 5 * 1000));

        // eslint-disable-next-line no-await-in-loop
        simulationData = await this.getSimulation(simulationData.id);
      }
    }

    if (!allContentAreaPromptsUpdated) {
      throw new Error('Prompt generation failed to complete for some content area');
    }

    return simulationData;
  };

  createSimulation = async (simulation) => {
    this.creatingSimulation = true;
    let data = null;

    try {
      const response = await Api.createSimulation(simulation);

      data = await this.waitForContentAreaSave(response);
    } finally {
      this.creatingSimulation = false;
    }

    return data;
  }

  updateSimulation = async (simulation) => {
    this.updatingSimulation = true;
    let data;

    try {
      const response = await Api.updateSimulation(simulation);

      data = await this.waitForContentAreaSave(response);
    } finally {
      this.updatingSimulation = false;
    }

    return data;
  }

  deleteSimulation = async (simulationId) => {
    const { data } = await Api.deleteSimulation(simulationId);
    await this.getSimulations();

    return data;
  }

  deleteSimulations = async (simulationIds) => {
    await Api.deleteSimulations(simulationIds);
    await this.getSimulations();
  }

  bulkTagSimulations = async ({ selectedTeamIds, simulationIds }) => {
    try {
      await Api.bulkTagSimulations({
        selectedTeamIds: selectedTeamIds.map(({ id }) => id),
        simulationIds,
      });

      return true;
    } catch (e) {
      throw new Error(e);
    }
  }
}

decorate(SimulationStore, {
  simulations: observable,
  selectedSimulation: observable,
  simulationsList: observable,
  getSimulations: action,
  getSimulationsList: action,
  getSimulation: action,
  createSimulation: action,
  updateSimulation: action,
  deleteSimulation: action,
  deleteSimulations: action,
  bulkTagSimulations: action,
});

export default new SimulationStore();
