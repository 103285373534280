// @flow
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBook,
  faCheck,
  faVideo,
  faTags,
  faArrowRight,
  faNewspaper,
  faGraduationCap,
  faVolumeUp,
  faMobileAlt,
  faLaptop,
  faFileAlt,
  faPlay,
  faFileDownload,
  faDownload,
  faPlusCircle,
  faEdit,
  faPrint,
  faInfoCircle,
  faPlayCircle,
  faPauseCircle,
  faSquareRootAlt,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCloudUpload,
} from '@fortawesome/pro-light-svg-icons';

import {
  faBell,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faBook,
  faCheck,
  faVideo,
  faTags,
  faArrowRight,
  faNewspaper,
  faGraduationCap,
  faVolumeUp,
  faMobileAlt,
  faLaptop,
  faFileAlt,
  faPlay,
  faFileDownload,
  faDownload,
  faPlusCircle,
  faEdit,
  faPrint,
  faInfoCircle,
  faCloudUpload,
  faPlayCircle,
  faPauseCircle,
  faSquareRootAlt,
  faBell,
);
